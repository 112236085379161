<template>
  <el-dialog
    title="选择联系人"
    :modal-append-to-body='appendToBody'
    :append-to-body="appendToBody"
    :visible.sync="visible"
    width="1000px">
    <div v-en-loading="loading">
      <en-transfer-data :config="transferConfig" ref="transfer" class='left-panel' v-model="selected" >
      </en-transfer-data>
      <div class="bottom">
        <en-button @click="handleClick" :disabled="!hasSelectedOne">确定</en-button>
      </div>
    </div>

  </el-dialog>

</template>

<script>
import { request } from "en-js";
import { team } from "@/api/chat";
import { selectService } from "@/api/select";
import enTransferData from "@/components/en-transfer-data";
import Utils from "@/tools/chat";

export default {
  name: "AddChats",
  props: {
    teamId: String,
    createTeam: Boolean,
    addMember: Boolean,
    appendToBody: {
      type: Boolean,
      default: false
    },
    owner: String,
    exclude: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: { enTransferData },
  data() {
    return {
      loading: false,
      pageKey: 0,
      selected: [],
      updateComKey: "1",
      visible: false,
      transferConfig: {
        isMulti: true, // 是否多选
        showTitle: false,
        resetLeft: false,
        hideRight: false,
        choiceRule: false,
        // excludes: [this.$store.userInfo],//过滤当前用户功能没有
        dependData: {
          // 当前服务引入与 src\views\intelligentAccounting\components\checkCashFlow\index.vue 104行中
          requestService: selectService, // 引入服务名称
          requestFn: "queryPersonTree", // 服务中方法
          requestParams: { pageNo: 1, pageSize: 9999, name: "" } // 请求参数

        }
      }
    };
  },
  mounted() {
  },
  computed: {
    hasSelectedOne() {
      // return this.selected.some(item => item.selected) //穿梭框 好像不能拿到真实选择的数据
      return this.selected && this.selected.length > 0;
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    handleClick() {
      const userlist = [].concat(this.selected);
      if (this.createTeam) {
        this.requestCreateTeam(userlist);
      } else if (this.addMember) {
        this.requestAddMember(userlist);
      } else {
        console.log("dismiss");
        this.dismiss(userlist);
      }
    },
    @request(true, "loading")
    async requestCreateTeam(userlist) {
      if (userlist.length === 1) { // 列表存在单聊 直接选中
        const imUserId = userlist[0].imUserId;
        const session = this.$store.state.chat.sessionlist.find((item) => item.to === imUserId);
        if (session) {
          this.$store.dispatch("chat/setCurrSession", session.id);
          this.dismiss(userlist, session.id);
          return;
        }
      }
      // 先同步资料
      const accounts = userlist.map((item) => item.imUserId || item.id) || [];
      const hasErrorCount = accounts.some((item) => !item);
      if (hasErrorCount) {
        this.$message.error("参数错误");
        return;
      }
      this.$store.dispatch("chat/searchUsers", { accounts });

      const res = await team.createTeam(userlist);
      const obj = {};
      obj.sessionId = Utils.getSessionId(this.$store.state.chat.userId, res.sessionId);
      obj.scene = userlist.length > 1 ? "team" : "p2p";
      this.$store.dispatch("chat/setCurrSession", `${obj.scene}-${obj.sessionId}`);
      this.dismiss(userlist);
    },
    @request(true, "loading")
    async requestAddMember(userlist) {
      // 先同步资料
      const accounts = userlist.map((item) => item.imUserId) || [];
      this.$store.dispatch("chat/delegateTeamFunction", {
        functionName: "addTeamMembers",
        options: {
          teamId: this.teamId,
          accounts,
          done: async(error) => {
            if (error) {
              this.$message.error(error);
              return;
            }
            await team.addGroupMember({
              sessionId: this.teamId,
              owner: `${ this.owner}`,
              userList: JSON.stringify(userlist.map((u) => u.imUserId))
            });
            this.dismiss(userlist);
          }
        }
      });
    },

    dismiss(userlist) {
      if (userlist && userlist.length > 0) {
        this.$emit("change", userlist);
      }
      this.selected = [];
      this.$refs.transfer.resultList = [];
      this.loading = false;
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  /deep/ .el-dialog__header {
    display: block !important;
  }
  //bug37292
  /deep/ .en-transfer .en-transfer-panel-body.with-search{
    height: calc(100% - 51px);
  }
  .add-chat-dialog{
    height: 70vh;
  }
  .left-panel{
    height: 450px;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
</style>
