var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择联系人",
        "modal-append-to-body": _vm.appendToBody,
        "append-to-body": _vm.appendToBody,
        visible: _vm.visible,
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("en-transfer-data", {
            ref: "transfer",
            staticClass: "left-panel",
            attrs: { config: _vm.transferConfig },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "en-button",
                {
                  attrs: { disabled: !_vm.hasSelectedOne },
                  on: { click: _vm.handleClick },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }